/**
 * Утилиты
 */
const Utils = {
  bbCodeToHtml(text = '') {
    text = this.stripHtml(text);
    const rB = /\[b\](.*?)\[\/b\]/ig;
    const rUrl = /\[url=(.*?)\](.*?)\[\/url\]/ig;
    const rIUrl = /\[iurl=(.*?)\](.*?)\[\/iurl\]/ig;
    const rSize = /\[size=(.*?)\](.*?)\[\/size\]/ig;
    const rFont = /\[font=(.*?)\](.*?)\[\/font\]/ig;
    const rColor = /\[color=(.*?)\](.*?)\[\/color\]/ig;
    const rBg = /\[bg=(.*?)\](.*?)\[\/bg\]/ig;
    const rP = /\[p\](.*?)\[\/p\]/ig;
    const rSup = /\[sup\](.*?)\[\/sup\]/ig;
    const rBr = /\[br\]/ig;
    const rUl = /\[ul\](.*?)\[\/ul\]/ig;
    const rOl = /\[ol\](.*?)\[\/ol\]/ig;
    const rLi = /\[li\](.*?)\[\/li\]/ig;
    const rDiv = /\[div\](.*?)\[\/div\]/ig;

    text = text
      .replace(rB, '<b>$1</b>')
      .replace(rUrl, '<a href="$1" target="_blank">$2</a>')
      .replace(rIUrl, '<a href="$1">$2</a>')
      .replace(rP, '<p>$1</p>')
      .replace(rSup, '<sup>$1</sup>')
      .replace(rBr, '<br/>')
      .replace(rUl, '<ul>$1</ul>')
      .replace(rOl, '<ol>$1</ol>')
      .replace(rLi, '<li>$1</li>')
      .replace(rDiv, '<div>$1</div>')
      .replace(rSize, '<span style="font-size:$1px">$2</span>')
      .replace(rFont, '<span style="font-family:$1, sans-serif !important">$2</span>')
      .replace(rColor, '<span style="color:$1">$2</span>')
      .replace(rBg, '<span style="background-color:$1">$2</span>');

    return text;
  },

  // Экранируем html
  stripHtml(str) {
    const tagsToReplace = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;'
    };
    return str.replace(/[&<>]/g, (tag) => tagsToReplace[tag] || tag);
  },

  createIframe(data) {
    const iframe = document.createElement('iframe');
    const noscript = document.createElement('noscript');
    Object.keys(data).forEach(key => {
      iframe[key] = data[key];
    });
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
  },

  addTag(tag, data) {
    if (tag === 'iframe') {
      this.createIframe(data);
      return;
    }
    const s = document.createElement(tag);
    Object.keys(data).forEach(key => {
      s[key] = data[key];
    });
    document.getElementsByTagName('head')[0].appendChild(s);
  },

  removeTags(data) {
    if (typeof window !== 'undefined') {
      const deletedTags = [];
      const tags = document.getElementsByTagName(data.tag);
      Object.keys(tags).forEach(l => {
        if (!tags[l]) return;
        if (data.rel) {
          if (Array.isArray(data.rel)) {
            data.rel.forEach(rel => {
              if (tags[l].rel === rel) {
                deletedTags.push(tags[l]);
              }
            });
          } else if (tags[l].rel === data.rel) {
            deletedTags.push(tags[l]);
          }
        }

        if (data.content && tags[l].content === data.content) {
          deletedTags.push(tags[l]);
        }

        if (data.alternate && tags[l].alternate === data.alternate) {
          deletedTags.push(tags[l]);
        }

        if (data.id && tags[l].id === data.id) {
          deletedTags.push(tags[l]);
        }
      });

      deletedTags.forEach(tag => {
        tag.remove();
      });
    }
  },

  // Получить GET параметры в виде объекта
  getQueryParam: function getQueryParam(url) {
    if (!url) return {};
    const r = /^.*\?(.+)$/;
    const get = url.replace(r, '$1');
    const split = get.split('&');
    if (split[0] === url) return {};
    const mapGet = split.reduce((acc, el) => {
      const spEl = el.split('=');
      acc[decodeURIComponent(spEl[0])] = decodeURIComponent(spEl[1]);
      return acc;
    }, {});
    return mapGet;
  },

  serializeQueryParams: function serializeQueryParams(obj = {}) {
    return Object.keys(obj)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  },

  getDataWithFallback(obj = {}, key) {
    const fallbackKey = /^\/ru\//.test(key) ? '/ru/' : '/';
    return obj[key] || obj[fallbackKey];
  },

  sendEvent(event) {
    if (!event) return;
    if (typeof window !== 'undefined' && window.ga && event) {
      window.ga('send', 'event', {
        eventCategory: 'outbound',
        eventAction: 'click',
        eventLabel: `${event} ${window.location.pathname}`
      });
    }
  }
};

export default Utils;
