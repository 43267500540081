import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Button,
  Menu,
  MenuItem
} from 'mw-style-react';
import cn from 'classnames';
import './Header.scss';
import UtilsApi from '../../utils/api';
import { Link } from '../../i18n';
import Image from '../../components/Image/Image';
import iconArrow from '../../images/arrowWite.svg';

const links = [
  {
    key: '/product',
    value: 'headerProduct',
    menu: [
      {key: '/product', value: 'headerProductProcessEngine', event: 'Header_process_engine'},
      {key: '/bot-platform', value: 'headerProductBotPlatform', event: 'Header_bot_platform'}
    ]
  },
  {
    key: '/solutions',
    value: 'headerSolutions',
    menu: [
      {key: '/software', value: 'headerSolutionsSoftware', event: 'Header_PO'},
      {key: '/manage-api', value: 'headerSolutionsApi', event: 'Header_API'},
      {key: '/code', value: 'headerSolutionsCode', event: 'Header_code'},
      {key: '/processes', value: 'headerSolutionsProcesses', event: 'Header_process'},
      {key: '/iot', value: 'headerSolutionsIot', event: 'Header_IoT'},
      {key: '/communication', value: 'headerSolutionsCommunication', event: 'Header_Communication'},
      {key: '/events', value: 'headerSolutionsEvents', event: 'Header_event'},
      {key: '/knowledge', value: 'headerSolutionsKnowledge', event: 'Header_knowlege'},
      {key: '/people', value: 'headerSolutionsPeople', event: 'Header_People'},
      {key: '/cloud', value: 'headerSolutionsCloud', event: 'Header_cloud'},
    ]
  },
  {
    key: '/case-studies',
    value: 'headerCaseStudies'
  },
  {
    key: '/new-pricing/public-cloud',
    value: 'headerPricing'
  },
  {
    key: '/learn',
    value: 'headerLearn',
    // menu: [
    //   {key: '/learn', value: 'headerLearn'},
    //   {key: 'learnContentStartButtonLink', value: 'learnContentStartTitle'},
    //   {key: '/tutorials', value: 'learnContentTutorialTitle'},
    //   {key: 'learnContentDocButtonLink', value: 'learnContentDocTitle'},
    //   {key: '/workshops', value: 'learnContentWorkShopTitle'},
    //   {key: '/certification', value: 'learnContentCertificationTitle'},
    // ]
  },
  {
    key: '/company',
    value: 'headerCompany',
    menu: [
      {key: '/about', value: 'headerCompanyAbout'},
      {key: '/blog', value: 'headerCompanyNews'},
      {key: '/partnership', value: 'headerCompanyPartnership'},
      {key: '/jobs', value: 'headerCompanyJobs'},
      // {key: 'brand-center', value: 'Brand Center'},
    ]
  },
];

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      isAuth: false,
      showAuthButton: false,
      guId: '',
    };
  }

  componentDidMount() {
    let isAuth = false;
    let showAuthButton = false;
    // let guId = '';
    UtilsApi.isAuth((res) => {
      if (res.login) {
        showAuthButton = true;
        isAuth = true;
        localStorage.setItem('userEmail', res.login);
      } else {
        // if (typeof window !== 'undefined' && window.ga) {
        //   guId = window.ga.getAll()[0].b.data.values[':clientId'];
        //   console.log(guId);
        // }
        showAuthButton = true;
        isAuth = false;
      }
      this.setState({
        isAuth,
        showAuthButton,
        // guId
      });
    });
  }

  renderLinks(intl) {
    return links.map((link, index) => {
      const active = link.key === this.props.page || link.key.indexOf(this.props.page) !== -1;
      if (link.menu) {
        return (
          <div
            key={index}
            className={cn('header__nav__item', { active })}
            onMouseEnter={() => this.openMenu(link.key)}
            onMouseLeave={() => this.closeMenu(link.key)}
            onClick={() => this.openMenu(link.key)}
          >
            {intl.formatMessage({id: link.value})}
            <div className="header__nav__list">
              {this.state.openMenu === link.key &&
                <Menu
                  onClose={() => this.closeMenu(link.key)}
                >
                  {link.menu.map((item, index) => (
                    <Link
                      key={index}
                      to={item.key}
                      event={item.event}
                    >
                      <MenuItem
                        className="header__nav__list__item"
                        label={intl.formatMessage({id: item.value})}
                        value={item.key}
                      />
                    </Link>
                  ))}
                </Menu>
              }
            </div>
          </div>
        );
      }

      return (
        <Link
          key={index}
          to={link.key}
          className={cn('header__nav__item', { active })}
          event={link.event}
        >
          {intl.formatMessage({id: link.value})}
        </Link>
      );
    });
  }

  openMenu(key) {
    this.setState({
      openMenu: key
    });
  }

  closeMenu() {
    this.setState({
      openMenu: ''
    });
  }

  clickClose(e) {
    e.stopPropagation();
    if (typeof window !== 'undefined') {
      localStorage.setItem('disabledSecondaryHeader', 'true');
    }
    this.forceUpdate();
  }

  render() {
    const { intl, isScrolled, mainHeader, page } = this.props;
    const { isAuth, showAuthButton } = this.state;
    const host = process.env.GATSBY_HOST;
    const renderLogo = mainHeader || isScrolled ? 'CorezoidProduct.png' : 'corezoid-logo.png';
    const regLnk = `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`;
    // if (guId) {
    //   regLnk = `${regLnk}?guId=${guId}`;
    // }

    return (
      <header>
        <div
          className={cn(`header ${page}Custom`, { isScrolled, mainHeader })}
        >
          <div
            className={cn('header__wrap')}
          >
            <Link
              to={''}
              className="header__logo"
            >
              <Image file={renderLogo} />
            </Link>
            <div className="header__nav">
              {this.renderLinks(intl)}
            </div>
            {showAuthButton ?
              <div className="header__auth">
                {isAuth ?
                  <Link
                    to={host}
                  >
                    <Button
                      className="header__auth__btn__big button__default"
                      label={`${intl.formatMessage({ id: 'continue' })}`}
                      size={'small'}
                    />
                  </Link> :
                  <div className="header__auth__buttons">
                    <Link
                      to={regLnk}
                      // to={`${saUrl}/signup?redirect_uri=${redirectUri}`}
                      event={'sign_up'}
                      id={'sign_up'}
                    >
                      <Button
                        className="header__auth__btn__sm button__default"
                        label={`${intl.formatMessage({ id: 'signUp' })}`}
                        size={'small'}
                      />
                    </Link>
                    <Link
                      to={host}
                      // to={`${saUrl}/login?redirect_uri=${redirectUri}`}
                      event={'sign_in'}
                      id={'sign_in'}
                    >
                      <Button
                        className="header__auth__btn__sm button__secondary"
                        label={`${intl.formatMessage({ id: 'signIn' })}`}
                        size={'small'}
                      />
                    </Link>
                  </div>
                }
              </div> :
              <div className="header__auth" />
            }
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  intl: PropTypes.object,
  page: PropTypes.string,
  isScrolled: PropTypes.bool,
  mainHeader: PropTypes.bool
};


export default injectIntl(Header);
