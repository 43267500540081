import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
// import {withPrefix} from 'gatsby-link';
import { localeData } from './locales';

addLocaleData(localeData);

export default ComposedComponent => {
  class withIntl extends Component {
    static childContextTypes = {
      language: PropTypes.object,
    };

    constructor(props) {
      super(props);
      const { pageContext } = props;
      const { locale, languages, originalPath } = pageContext;
      let language = locale;

      // определения локализации и редирект на нужный язык
      if (typeof window !== 'undefined') {
      //   language = window.localStorage.getItem('language');
      //   const pathname = window.location.pathname;
      //
      //   // используем локализацию браузера
      //   if (!language) {
      //     language = window.navigator.language.split('-')[0];
      //     // если язык не потдерживаем
      //     if (languages.findIndex(l => l.value === language) === -1) {
      //       language = 'en';
      //     }
      //     window.localStorage.setItem('language', language);
      //   }
      //
      //   // только для главной страници
      //   // редиректим когда известный язык пользователя не 'en'
      //   if (
      //     pathname === '/' &&
      //     language !== 'en'
      //   ) {
      //     window.location.replace(withPrefix(`/${language}${pathname}`));
      //     // window.history.pushState('', '', `/${language}${pathname}`);
      //   } else {
      //     // для остальных страниц сохраняем язык ссылки
        language = locale || 'en';
        window.localStorage.setItem('language', language);
      //   }
      }

      this.state = {
        language: {
          locale: language,
          languages,
          originalPath,
        },
      };
    }

    getChildContext() {
      const { language } = this.state;
      return {
        language,
      };
    }

    render() {
      const { language } = this.state;
      const locale = language.locale || 'en';
      const messages = require(`./locales/${locale}/index.js`).default; // eslint-disable-line

      return (
        <IntlProvider locale={locale} messages={messages}>
          <ComposedComponent {...this.props} />
        </IntlProvider>
      );
    }
  }

  withIntl.propTypes = {
    pageContext: PropTypes.object
  };

  return withIntl;
};
