const titlesAndDescription = {
  '/': {
    title: 'Corezoid Actor Engine',
    description: 'Want to be a tech tycoon? Build you digital core and rule the technologies!'
  },
  '/manage-api/': {
    title: 'Manage external APIs and create your own',
    description: 'There are millions of APIs, and each one has its unique protocol. Corezoid is a universal metalanguage that allows fast creation and management of existing APIs using a single interface.'
  },
  '/events/': {
    title: 'Control.Events - Keep everything under control!',
    description: 'Lack Lack of control is one of the key problems facing companies. Control.events helps you effectively monitor and manage events that occur both inside and outside your company.'
  },
  '/case-studies/': {
    title: 'Case Studies',
    description: 'Learn how companies use Corezoid Actor Engine to manage their business, lower their spendings, increase their time-to-market speed and achieve outstanding results.'
  },
  '/learn/': {
    title: 'Learn Corezoid',
    description: 'Do you want to learn Corezoid but don’t know how to begin? We suggest various training options for different client types and specialist grades: starting with free education materials up to individual corporate training. Choose an option you like and a training schedule and get going to success together with Corezoid!'
  },
  '/communication/': {
    title: 'Control and automate communications',
    description: 'Customers want to communicate with businesses via dozens of channels. Corezoid helps you manage all these channels and automates your communications with bots.'
  },
  '/certification/': {
    title: 'Corezoid Certification',
    description: 'The demand for certified Corezoid specialists grows every day. Prove your qualification and you will receive access to a community of certified specialists and Corezoid partners that can help you find new interesting projects or even a job.'
  },
  '/iot/': {
    title: 'Control the “IoT jungle”',
    description: 'The world is filled with hundreds of thousands of devices from myriad of vendors, each having their own protocol. Corezoid helps merge this “hardware zoo” into a unified ecosystem and enables you to manage it effectively.'
  },
  '/code/': {
    title: 'Use any public code in the world, written in any language',
    description: 'Why reinvent the wheel, when the code you need is already out there. Corezoid helps you find the required code in any git-repository and launch it as a part of your project.'
  },
  '/about/': {
    title: 'About Corezoid Inc',
    description: 'Our mission is to make every company in the world a technologically agile and train it to interact with other companies'
  },
  '/jobs/': {
    title: 'Vacancies',
    description: 'We always seek the best talent to join our team. If you want to become part of a company that changes the world, find a vacancy that matches your capabilities and apply.'
  },
  '/people/': {
    title: 'Manage leads, customers, employees, partners, suppliers...',
    description: 'Transfer your people management technology to Corezoid: create sales funnels, manage customer life cycles, suppliers and inventory; track fulfillment and non-fulfillment of KPIs, as well as employee tasks.'
  },
  '/blog/': {
    title: 'News',
    description: 'The latest news about Corezoid Actor Engine'
  },
  '/new-pricing/support/': {
    title: 'Support',
    description: 'Learn more about terms and conditions of Corezoid Support'
  },
  '/new-pricing/public-cloud/': {
    title: 'Pricing',
    description: 'Pay as you go'
  },
  '/form/caab/': {
    title: 'Corezoid as a Back-end',
    description: 'Отправьте запрос на получение доступа и документации к использованию Corezoid для управления вашим веб или мобильным приложением.'
  },
  '/form/certification-corp/': {
    title: 'Sales',
    description: 'Want to deploy Corezoid on your own environment? Contact us to discuss it!'
  },
  '/form/certification-employees/': {
    title: 'Corezoid specialists certification',
    description: 'Corezoid specialist certificate shows employers that you are skilled to solve key challenges.'
  },
  '/form/certification-partner/': {
    title: 'Corezoid partner certification',
    description: 'Certified Corezoid partner, apart from the status, also receives access to a closed community of partners for experience and client exchange, exclusive training webinars for employees, lead stream from Corezoid.com and a list of certified specialists that are looking for jobs.'
  },
  '/form/extended-trial/': {
    title: 'Request extended trial',
    description: '30 days weren\'t enough to dive deep into Corezoid? No problem. Request to extend the trial period to get better understanding of Corezoid Actor Engine.'
  },
  '/form/live-demo/': {
    title: 'Live Demo',
    description: 'Would you like to see how Corezoid can help you? Request a live demo and our expert will demonstrate the powerful features and capabilities of Corezoid and will be happy to answer your questions regarding our product.'
  },
  '/form/sales/': {
    title: 'Sales',
    description: 'Want to deploy Corezoid on your own environment? Contact us to discuss it!'
  },
  '/form/support/': {
    title: 'Support',
    description: 'Do you want to buy Corezoid and have questions about support?'
  },
  '/form/webinar/': {
    title: 'Bot Platform 2.0: Начало',
    description: 'Bot Platform 2.0: Начало'
  },
  '/form/workshop/': {
    title: 'Workshop',
    description: 'This is the fastest method to dive into Corezoid. We create individual education programs based on your needs, as a result you receive not only a well educated team, but also a prototype of a real business process.'
  },
  '/media/': {
    title: 'Media',
    description: 'News, articles and other materials related to Corezoid Inc.'
  },
  '/product/': {
    title: 'Corezoid Actor Engine Anatomy',
    description: 'Explore the power of Corezoid for each team member'
  },
  '/workshops/': {
    title: 'Workshops',
    description: 'This is the fastest method to dive into Corezoid. We create individual education programs based on your needs, as a result you receive not only a well educated team, but also a prototype of a real business process.'
  },
  '/processes/': {
    title: 'Focus on the process, not the code',
    description: 'Move your business logic from legacy code to Corezoid processes and speed up the creation of new products while lowering support cost.'
  },
  '/knowledge/': {
    title: 'DeepMemo - manage company information and knowledge',
    description: 'Company information and knowledge are hidden in data and stored in dozens of separate softwares with different formats.'
  },
  '/cloud/': {
    title: 'Cloud copmuting, without clouds',
    description: 'Admit it, you need the results of computing, not the process. Create and run algorithms of any complexity on Corezoid cloud that is secure and autoscales.'
  },
  '/terms-and-conditions/': {
    title: 'Terms and conditions',
    description: 'Learn more about terms and conditions of Corezoid.com'
  },
  '/software/': {
    title: 'Master the software jungle',
    description: 'Every company has dozens of software solutions from various vendors. Corezoid can become your zookeeper for them, uniting diverse species into a single effectively managed software ecosystem.'
  },
  '/ru/': {
    title: 'Corezoid Actor Engine',
    description: 'Хочеть стать цифровым магнатом? Построй свое цифровое ядро и управляй технологиями!'
  },
  '/ru/manage-api/': {
    title: 'Управляйте внешними и создавайте свои API',
    description: 'В мире миллионы API, и каждый имеет свой уникальным протоколом. Corezoid - это универсальный метаязык, который позволяет быстро создавать новые и управлять существующими API в едином интерфейсе.'
  },
  '/ru/events/': {
    title: 'Control.events - держи все под контролем!',
    description: 'Отсутствие контроля - это одна из ключевых проблем всех компаний. Control.events поможет эффективно отслеживать и управлять событиями, которые происходят как внутри, так и снаружи компании.'
  },
  '/ru/case-studies/': {
    title: 'Кейсы Corezoid',
    description: 'Узнайте как разные компании используют Corezoid Actor Engine для управления бизнесом, сокращения расходов, увеличения скорости time-to-market и добиваются выдающихся результатов.'
  },
  '/ru/learn/': {
    title: 'Изучение Corezoid',
    description: 'Хотите начать изучение Corezoid, но не знаете с чего начать? Мы предлагаем разные форматы обучения для разных типов клиентов и уровней специалистов: от бесплатных учебных материалов до индивидуального корпоративного обучения.'
  },
  '/ru/communication/': {
    title: 'Управляйте и автоматизируйте коммуникации',
    description: 'Клиенты хотят общаться с бизнесом через десятки каналов коммуникации. Corezoid поможет управлять всеми каналами коммуникации и автоматизировать общение с помощью роботов.'
  },
  '/ru/certification/': {
    title: 'Сертификация Corezoid',
    description: 'Спрос на сертифицированных специалистов Corezoid растет с каждым днем. Подтвердите свою квалификацию и вам откроется доступ к сообществу сертифицированных специалистов и партнеров Corezoid, которое поможет вам найти новые интересные проекты и даже работу.'
  },
  '/ru/iot/': {
    title: 'Управляйте “зоопарком” IoT',
    description: 'В мире сотни тысяч девайсов от разных поставщиков, и каждый имеет свой протокол. Corezoid поможет объединить этот “железный зоопарк” в единую экосистему и эффективно управлять им.'
  },
  '/ru/code/': {
    title: 'Используйте код всего мира на любом языке программирования',
    description: 'Зачем изобретать велосипед? Почти весь код уже написан. Corezoid позволяет найти нужный код на любом git-репозитории и запустить его как часть вашего продукта.'
  },
  '/ru/about/': {
    title: 'О Corezoid Inc',
    description: 'Наша миссия - сделать каждую компанию в мире технологическим магнатом и научить взаимодействовать с другими компаниями.'
  },
  '/ru/jobs/': {
    title: 'Вакансии Corezoid',
    description: 'Мы всегда ищем лучшие таланты в нашу команду. Если вы хотите стать частью компании, которая изменяет мир, найдите подходящую вам вакансию и подайте заявку.'
  },
  '/ru/people/': {
    title: 'Управляйте лидами, клиентами, сотрудниками, партнерами, поставщиками...',
    description: 'Перенесите технологии управления людьми в Corezoid: создавайте воронки продаж, управляйте жизненным циклом клиентов, поставщиками и складскими запасами; отслеживайте выполнение и отсутствие выполнения KPI и задач сотрудников.'
  },
  '/ru/blog/': {
    title: 'Новости',
    description: 'Последние новости о Corezoid Actor Engine и наших партнерах'
  },
  '/ru/pricing/support/': {
    title: 'Поддержка',
    description: 'Узнайте больше о условиях и форматах поддержки Corezoid Actor Engine'
  },
  '/ru/pricing/public-cloud/': {
    title: 'Тарифы',
    description: 'Оплачивайте столько, сколько используете'
  },
  '/ru/form/caab/': {
    title: 'Corezoid as a Back-end',
    description: 'Отправьте запрос на получение доступа и документации к использованию Corezoid для управления вашим веб или мобильным приложением.'
  },
  '/ru/form/certification-corp/': {
    title: 'Корпоративная сертификация',
    description: 'Мы создаем групповой звонок в Zoom / Skype или другом удобном для вас канале видеосвязи и проводим 3-дневный удаленное обучение ваших специалистов'
  },
  '/ru/form/certification-employees/': {
    title: 'Сертификация для специалистов Corezoid',
    description: 'Наличие сертификата Corezoid демонстрирует работодателю что у вас есть необходимые навыки и знания для решения поставленных задач.'
  },
  '/ru/form/certification-partner/': {
    title: 'Сертификация партнеров Corezoid',
    description: 'Сертифицированный партнер Corezoid помимо статуса, получает доступ к закрытому сообществу партнеров для обмена опытом и клиентами, эксклюзивным вебинарам для повышения квалификации сотрудников, потоку лидов с сайта Corezoid.com и списку сертифицированных специалистов, которые ищут работу.'
  },
  '/ru/form/extended-trial/': {
    title: 'Запросить продление тестового периода',
    description: 'Не хватило 30 дней для изучения Corezoid? Без проблем! Мы с радостью продлим Ваш тестовый период по запросу.'
  },
  '/ru/form/live-demo/': {
    title: 'Live Demo',
    description: 'Хотите узнать как Corezoid Actor Engine может помочь вашему бизнесу?'
  },
  '/ru/form/sales/': {
    title: 'Отдел продаж',
    description: 'Хотите узнать больше о тарификации, вариантах хостинга и других вопросах, связанных с покупкой Corezoid? Свяжитесь с нашим отделом продаж и мы с радостью ответим на все ваши вопросы!'
  },
  '/ru/form/support/': {
    title: 'Поддержка',
    description: 'Возникли проблемы или вопросы по использованию и изучению Corezoid?'
  },
  '/ru/form/webinar/': {
    title: 'Bot Platform 2.0: Начало',
    description: 'Bot Platform 2.0: Начало'
  },
  '/ru/form/workshop/': {
    title: 'Воркшоп',
    description: 'Самый быстрый способ изучения Corezoid. Мы создаем индивидуальную программу обучения под ваши потребности, и вы на выходе получаете не только обученную технологии Corezoid команду, но и прототип реального бизнес-процесса.'
  },
  '/ru/media/': {
    title: 'Медиа',
    description: 'Новости, статьи и другие материалы, связанные c Corezoid, Inc.'
  },
  '/ru/product/': {
    title: 'Анатомия Corezoid Actor Engine',
    description: 'Узнайте больше о возможностях Corezoid'
  },
  '/ru/workshops/': {
    title: 'Воркшоп',
    description: 'Самый быстрый способ изучения Corezoid. Мы создаем индивидуальную программу обучения под ваши потребности, и вы на выходе получаете не только обученную технологии Corezoid команду, но и прототип реального бизнес-процесса.'
  },
  '/ru/processes/': {
    title: 'Фокусируйтесь на процессах, а не их программировании',
    description: 'Переносите бизнес-логику из кода и устаревшего ПО в процессы Corezoid, чтобы ускорить создание новых продуктов и снизить расходы на поддержку.'
  },
  '/ru/knowledge/': {
    title: 'DeepMemo - управляйте информацией и знаниями компании',
    description: 'Информация и знания компании скрыты в данных и хранятся в десятках разрозненных ПО в разных форматах. Облачная экспертная система DeepMemo поможет объединить данные из разных источников в едином хранилище и извлечь из сырых данных информацию и знания для принятия более качественных и эффективных решений: от супер персонализации до антифрода.'
  },
  '/ru/cloud/': {
    title: 'Стать облачным - легко!',
    description: 'Признайтесь, вам нужны результаты вычислений, а не то как они происходят. Создавайте и запускайте алгоритмы любой сложности в автомасштабируемом и безопасном облаке Corezoid.'
  },
  '/ru/terms-and-conditions/': {
    title: 'Условия и правила',
    description: 'Узнайте больше об условиях и правилах использования Corezoid.com'
  },
  '/ru/software/': {
    title: 'Управляйте “зоопарком” ПО от разных поставщиков',
    description: 'В каждой компании есть десятки ПО от разных поставщиков. Corezoid поможет объединить этот “зоопарк” ПО в единую экосистему и эффективно управлять им.'
  }
};

export default titlesAndDescription;
