import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Utils from '../utils/utils';

const I18nLink = ({ to, children, event, ...rest }, { language }) => {
  // если передается ссылка то переходим по ней
  if (to.indexOf('http') !== -1) {
    return (
      <div onClick={() => Utils.sendEvent(event)} {...rest}>
        <a
          href={to}
          target={'_blank'}
          rel="noopener"
        >
          {children}
        </a>
      </div>
    );
  }

  const { locale } = language;
  const activeLang = locale === 'en' ? '' : locale;
  let toWithLang = activeLang ? `/${activeLang}${to}` : `${to}`;
  if (typeof window !== 'undefined' && !window.location.hash) {
    toWithLang += '/';
  }

  return (
    <div onClick={() => Utils.sendEvent(event)} {...rest}>
      <Link to={toWithLang}>
        {children}
      </Link>
    </div>
  );
};

I18nLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  event: PropTypes.string,
};

I18nLink.contextTypes = {
  language: PropTypes.object,
};

export default I18nLink;
