import Utils from './utils';

/**
 * Утилиты
 */
const UtilsApi = {
  fetchApi(url, method, callback, data) {
    if (typeof window !== 'undefined') {
      window.fetch(url, {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(res2 => callback(res2));
    }
  },

  sendUserInfoInProcess(sendData) {
    const url = process.env.GATSBY_NOTIFICATION_URL;

    sendData.currentToken = localStorage.getItem('currentToken');
    sendData.trigger = 'corezoid.com';
    fetch(url, {
      method: 'POST',
      header: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
      },
      body: JSON.stringify(sendData),
      mode: 'no-cors',
    });
  },

  // Проверка авторизации
  isAuth(callback) {
    const subscribe = (messaging, sendData) => {
      // запрашиваем разрешение на получение уведомлений
      messaging.requestPermission()
        .then(() => {
          if (localStorage.getItem('currentToken')) {
            messaging.deleteToken(localStorage.getItem('currentToken'))
              .then(() => {
                console.log('Token deleted');
                localStorage.setItem('currentToken', '');
              })
              .catch((error) => {
                console.log('Unable to delete token', error);
              });
          }

          // получаем ID устройства
          messaging.getToken()
            .then((currentToken) => {
              localStorage.setItem('currentToken', currentToken);
              localStorage.setItem('isSendFBsLOGIN', '');

              this.sendUserInfoInProcess(sendData);
            })
            .catch((err) => {
              console.warn('При получении токена произошла ошибка.', err);
            });
        })
        .catch((err) => {
          console.warn('Не удалось получить разрешение на показ уведомлений.', err);
          localStorage.setItem('currentToken', '');
        });
    };

    const cb = (data) => {
      const lazyApp = import('firebase/app');
      import('@firebase/messaging');

      if (process.env.GATSBY_HOST !== 'https://admin.dev.corezoid.com') {
        if (data.user_id && typeof window !== 'undefined') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            UserID: data.user_id
          });
        }

        Promise.all([lazyApp]).then(([firebase]) => {
          setTimeout(() => {
            if (!firebase.apps.length) {
              firebase.initializeApp({
                apiKey: 'AIzaSyB1Fwry3CWtAZzoQ064maY55LUk_3m5QXI',
                projectId: 'handy-balancer-256012',
                messagingSenderId: '795501795105',
                appId: '1:795501795105:web:3826cb4aa31b17bc565060',
              });
            }

            let messaging = firebase.messaging();

            if (firebase.messaging.isSupported()) {
              messaging = firebase.messaging();
            }

            const sendData = {
              login: data.login,
              nick: data.nick,
              user_photo: data.user_photo,
              trigger: 'corezoid.com'
            };

            if (data.login &&
              !localStorage.getItem('isSendFBsLOGIN') &&
              localStorage.getItem('currentToken')
            ) {
              this.sendUserInfoInProcess(sendData);
              localStorage.setItem('isSendFBsLOGIN', true);
            } else {
              localStorage.setItem('isSendFBsLOGIN', '');
            }

            if ('Notification' in window) {
              // пользователь уже разрешил получение уведомлений
              // подписываем на уведомления если ещё не подписали
              if (Notification.permission !== 'granted') {
                subscribe(messaging, sendData);
              }
            }

            messaging.onMessage((payload) => {
              navigator.serviceWorker.register('/firebase-messaging-sw.js');
              Notification.requestPermission((permission) => {
                if (permission === 'granted') {
                  navigator.serviceWorker.ready.then((registration) => {
                    // Copy data object to get parameters in the click handler
                    payload.data.data = JSON.parse(JSON.stringify(payload.data));

                    registration.showNotification(payload.data.title, payload.data);
                  }).catch((error) => {
                    // registration failed :(
                    console.log('ServiceWorker registration failed', error);
                  });
                }
              });
            });
          }, 1000);
        });
      }

      return callback(data);
    };
    return this.fetchApi('/auth/me', 'GET', cb);
  },

  initializePay(tariff, callback) {
    const data = {
      ops: [{
        tariff: tariff.toLowerCase(),
        action_type: 'initialize',
        type: 'create',
        obj: 'subscription',
      }],
    };

    return this.fetchApi('/api/2/json', 'POST', callback, data);
  },

  createPay(orderId, token, callback) {
    const data = {
      ops: [{
        order_id: orderId,
        action_type: 'create',
        type: 'create',
        obj: 'subscription',
        token,
      }],
    };
    return this.fetchApi('/api/2/json', 'POST', callback, data);
  },

  // отправка формы
  sendForm(data, url, callback, errorCallback) {
    fetch(url, {
      method: 'POST',
      header: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
      },
      body: JSON.stringify(data),
      mode: 'no-cors',
    }).then(() => {
      callback();
    }).catch(() => {
      if (errorCallback) errorCallback();
    });
  },

  redirectJobs(fields) {
    const redirectUrl = process.env.GATSBY_SIMULATOR_CV;

    const getQueryParams = (fields) => {
      const queryParams = {
        source: 'corezoidcom',
      };
      let type = 'untech';

      fields.forEach(field => {
        if (field.type === 'cvLink' && field.value) {
          queryParams.cvform = field.value;
        }

        if (field.type === 'position' && field.value) {
          const position = field.list.find(listItem => listItem.value === field.value);
          queryParams.vacancy = field.value;

          if (position) {
            type = position.type;
          }
        }
      });

      return {
        type,
        params: Utils.serializeQueryParams(queryParams),
      };
    };

    if (typeof window !== 'undefined') {
      const { type, params } = getQueryParams(fields);
      const url = `${redirectUrl}/${type}?${params}`;
      document.location.href = url;
    }
  }
};

export default UtilsApi;
