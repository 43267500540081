import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import { MenuItem, Select, Label, TextField, Button } from 'mw-style-react';
import { withPrefix } from 'gatsby-link';
import './Footer.scss';
import { Link } from '../../i18n';
import UtilsApi from '../../utils/api';
import Image from '../Image/Image';
import {
  iconArrow
} from '../../images';
const host = process.env.GATSBY_HOST;

let location = '';
if (typeof window !== 'undefined') {
  location = window.location.origin;
}

// import apple from '../../../images/platforms/apple-logo-grey.webp';
// import android from '../../../images/platforms/android-logo-grey.webp';
// import windows from '../../../images/platforms/windows-logo-grey.webp';
// import linux from '../../../images/platforms/linux-logo-grey.webp';

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lang: props.intl.locale,
      email: '',
      sendSubscribe: false,
      error: true,
      trySend: false
    };
  }

  handleChangeEmail(data) {
    this.setState({
      email: data.value,
      error: data.error,
      trySend: false
    });
  }

  handleSubscribe() {
    if (this.state.error) {
      this.setState({
        trySend: true
      });
      return;
    }

    const body = {
      form_id: 'subscribeForm',
      action: 'subscribe',
      fields: [{
        key: 'email',
        value: this.state.email
      }]
    };
    const url = `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`;
    UtilsApi.sendForm(body, url);
    this.setState({
      email: '',
      sendSubscribe: true,
    });
  }

  renderInput(intl, item) {
    if (this.state.sendSubscribe) {
      return (
        <div className="footer__column__item__input__done" key={'input'}>
          <Label value={intl.formatMessage({id: item.textDane})} />
        </div>
      );
    }
    return (
      <div key={`key_${item.text}`}>
        <div className="footer__column__item__input__wrap">
          <TextField
            className="footer__column__item__input"
            placeholder={this.props.intl.formatMessage({id: item.text})}
            value={this.state.email}
            length={40}
            type={'email'}
            onChange={(data) => this.handleChangeEmail(data)}
          />
          <Button
            className={'button__default footer__column__item__input__btn'}
            label={intl.formatMessage({id: item.buttonText})}
            onClick={() => this.handleSubscribe()}
            id={'news_subscribe'}
          />
        </div>
        {this.state.trySend && this.state.error &&
          <Label className="footer__column__item__input__error" value={'invalid email'} />
        }
      </div>
    );
  }

  renderSocial(intl, item) {
    return (
      <div className="footer__column__item__social" key={'links'}>
        {item.links.map(link => (
          <Link to={link.link} key={`id_${link.link}`}>
            <Image
              className="footer__column__item__social__image"
              file={link.image}
            />
          </Link>
        ))}
      </div>
    );
  }

  renderLang(intl) {
    const { lang } = this.state;
    return (
      <Fragment key={lang}>
        {lang !== 'en' ? (
          <div>
            <Select
              id={lang}
              className="footer__lang"
              // label={intl.formatMessage({id: `lang${lang}`})}
              value={lang}
              onChange={(data) => this.handleChange(data)}
            >
              <MenuItem
                label={`🇺🇸  ${intl.formatMessage({id: 'langen'})}`}
                value={'en'}
              />
              <MenuItem
                label={`🇷🇺  ${intl.formatMessage({id: 'langru'})}`}
                value={'ru'}
              />
            </Select>
          </div>
      ) : null}
      </Fragment>
    );
  }

  renderColumn(list, intl) {
    return list.map((column, index) => (
      <div
        key={index}
        className="footer__column"
      >
        <h3 className="footer__column__title">
          {intl.formatMessage({id: column.title})}
        </h3>
        <div className="footer__column__list">
          {column.list.map((item, index) => {
            const isFavorite = item.favorite;
            if (item.type === 'image') {
              return <img key={index} className="footer__column__item__img" src={item.src} alt="AWS"/>;
            }

            if (item.type === 'text') {
              return (
                <div className="footer__column__item__text" key={index}>
                  <Label value={intl.formatMessage({id: item.text})} />
                </div>
              );
            }

            if (item.type === 'input') {
              return this.renderInput(intl, item);
            }

            if (item.type === 'social') {
              return this.renderSocial(intl, item);
            }

            if (item.type === 'lang') {
              return this.renderLang(intl);
            }


            if (item.link.indexOf('http') !== -1) {
              return (
                <Link
                  key={index}
                  to={item.link}
                  className={cn('footer__column__item', { highlight: isFavorite})}
                >
                  <p>
                    {intl.formatMessage({id: item.name})}
                  </p>
                  {isFavorite && (
                    <img src={iconArrow} alt="More"/>
                  )}
                </Link>
              );
            }

            return (
              <Link
                key={index}
                to={item.link}
                className={cn('footer__column__item', { highlight: isFavorite})}
              >
                <p>
                  {intl.formatMessage({id: item.name})}
                </p>
                {isFavorite && (
                  <img src={iconArrow} alt="More"/>
                )}
                {item.icons &&
                  item.icons.map((icon, index) => (
                    <img
                      className={'footer__column__item__img'}
                      key={index}
                      src={icon}
                      alt="index"
                    />
                  ))
                }
              </Link>
            );
          })}
        </div>
      </div>
    ));
  }

  handleChange(data) {
    let language = data.value;
    if (language !== this.state.lang) {
      if (typeof window !== 'undefined') {
        let pathname = window.location.pathname;
        if (this.state.lang !== 'en') {
          pathname = window.location.pathname.replace(this.state.lang, '');
        }
        window.localStorage.setItem('language', language);
        if (language === 'en') {
          language = '';
        }
        const newUrl = withPrefix(`/${language}${pathname}`);
        window.location.replace(newUrl);
      }
    }
  }

  render() {
    const { intl } = this.props;
    const { lang } = this.state;
    const year = new Date().getFullYear();

    const footer = [
      {
        title: 'footerLinkResources',
        list: [
          {
            name: 'footerLinkQuickStart',
            link: lang === 'en' ? 'https://doc.corezoid.com/docs/quick-start-1' : 'https://doc.corezoid.com/docs/quick-start-1',
            favorite: false
          },
          { name: 'footerLinkTutorials', link: '/tutorials', favorite: false },
          {
            name: 'footerLinkDocs',
            link: lang === 'en' ? 'https://doc.corezoid.com/docs' : 'https://doc.corezoid.com/v2/docs',
            favorite: false
          },
          { name: 'footerLinkWorkshops', link: '/workshops', favorite: false },
          { name: 'footerLinkCertification', link: '/certification', favorite: false },
          { name: 'footerLinkFaq', link: '/faq', favorite: false },
          // { name: 'footerLinkCommunity', link: 'https://community.corezoid.com/categories', favorite: false },
        ],
      },
      {
        title: 'footerLinkCompany',
        list: [
          { name: 'footerLinkAbout', link: '/about', favorite: false },
          { name: 'footerLinkBlog', link: '/blog', favorite: false },
          { name: 'footerLinkJobs', link: '/jobs', favorite: false },
          { name: 'footerLinkMedia', link: '/media', favorite: false },
          { name: 'footerLinkPrivacyTerms', link: '/terms-and-conditions', favorite: false },
          { name: 'footerLinkContactUs', link: '/about', favorite: true },
        ],
      },
      {
        title: 'footerLinkDownload',
        list: [
          { name: 'footerLinkVMware', link: '/download', favorite: false },
          { name: 'footerLinkPresentations', link: 'https://docs.google.com/presentation/d/1vgG2Gx9Hcy4ssP3dljIVcaIaS2Bsenps7HJubOt_jC4/edit#slide=id.g3672661c4d_0_0', favorite: false },
          { name: 'footerLinkWhitepapers', link: 'https://docs.google.com/document/d/17fpl4-cs5g7Yqimjolq6rQdsUizO5niqUCREUCQpRhY/edit', favorite: false },
          // { type: 'image', name: 'aws', src: imgAws},
          { type: 'lang'}
          // { name: 'footerLinkMarketingKit', link: '/', favorite: false },
          // {
          //   name: 'MobileApp',
          //   link: '/',
          //   favorite: false,
          //   icons: [apple, android]
          // },
          // {
          //   name: 'DesktopApp',
          //   link: '/',
          //   favorite: false,
          //   icons: [apple, windows, linux]
          // },
        ],
      },
      {
        title: 'footerLinkSubscribe',
        list: [
          { type: 'text', text: 'footerTextSubscribe'},
          {
            type: 'input',
            text: 'footerInputSubscribe',
            buttonText: 'footerButtonSubscribe',
            textDane: 'footerDoneSubscribe'
          },
          {
            type: 'social',
            links: [
              {name: 'linkedIn', link: 'https://www.linkedin.com/company/middleware-official', image: 'linkedIn.png'},
              {name: 'fb', link: 'https://www.facebook.com/corezoid', image: 'facebook.png'},
              {name: 'twitter', link: 'https://twitter.com/corezoid', image: 'twitter.png'},
              {name: 'youtube', link: 'https://www.youtube.com/channel/UCoD8fgpWogDsWqk3fHkRrvA', image: 'youtube.png'},
              {name: 'rss', link: `${location}/rss.xml`, image: 'rss.png'},
            ]
          },
        ],
      }
    ];

    return (
      <footer className="footer">
        <div className="footer">
          <div className="grid-wrapper">
            <div className="footer__nav">
              {this.renderColumn(footer, intl)}
            </div>
          </div>
          <div className="footer__copyrights">
            <div className="footer__copyrights__image middleware"><Image file={'middleware-logo.svg'} /></div>
            <div className="footer__copyrights__text">
              <p>
                {`${year} © Corezoid Inc., 2093 PHILADELPHIA PIKE #2235 CLAYMONT 19703 DE, US. All rights reserved.`}
              </p>
              <p>
                Patent protected.
              </p>
            </div>
            <div className="footer__copyrights__images">
              <div className="footer__copyrights__image recycle">
                <Image file={'recycleBlack.png'} />
              </div>
              <div className="footer__copyrights__image aws">
                <Image file={'awsFooter.png'} />
              </div>
            </div>
            {/*
              <div className="footer__copyrights__image google">
                <Image file={'google-partner.png'} />
              </div>
            */}
            {/*
              <div className="footer__copyrights__image azure">
                <Image file={'azure-certified.png'} />
              </div>
            */}
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  intl: PropTypes.object,
};


export default injectIntl(Footer);
