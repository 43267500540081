import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import './Image.scss';

const renderImage = (value, className) => {
  if (!value) return;
  const {node} = value;
  const {
    // childImageSharp,
    extension,
    publicURL,
    relativePath
  } = node;
  if (extension === 'svg') {
    return (
      <img
        className={`image__svg ${className}`}
        src={publicURL}
        alt={relativePath}
      />
    );
  }

  return (
    <img
      className={`image ${className}`}
      src={publicURL}
      alt={relativePath}
    />
  );
  // return (
  //   <Img
  //     className={className}
  //     fluid={childImageSharp.fluid}
  //     imgStyle={imgStyle}
  //   />
  // );
};

const Image = props => {
  const { file, className } = props;
  const objFit = props.objFit ? props.objFit : 'cover';
  const objPosition = props.objPosition ? props.objPosition : '50% 50%';
  const imgStyle = {
    ...props.imgStyle,
    objectFit: objFit,
    objectPosition: objPosition,
    fontFamily: `"object-fit: ${objFit}; object-position: ${objPosition}"`,
  };
  if (file.indexOf('http') !== -1) {
    return (
      <img
        className={className}
        src={file}
        alt={file}
        width={width}
      />
    );
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif|svg/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={
        ({images}) => renderImage(
          images.edges.find(image => image.node.relativePath === file),
          className,
          imgStyle
        )
      }
    />
  );
};


Image.defaultProps = {
  objFit: '',
  objPosition: '',
  imgStyle: {}
};

Image.propTypes = {
  file: PropTypes.string.isRequired,
  className: PropTypes.string,
  objFit: PropTypes.string,
  objPosition: PropTypes.string,
  imgStyle: PropTypes.object
};


export default Image;
