const formatJpg = 'jpg';
let formatPng = 'png';

if (
  typeof window !== 'undefined' &&
  (
    /edge/.test(navigator.userAgent.toLowerCase())
  )
) {
  formatPng = 'png';
} else if (
  typeof window !== 'undefined' &&
  (
    /chrome/.test(navigator.userAgent.toLowerCase()) ||
    /opera/.test(navigator.userAgent.toLowerCase())
  )
) {
  formatPng = 'webp';
}

// caab
const cloudCaab = require(`../images/cloud_icon.${formatPng}`);
const apiCaab = require(`../images/rosette.${formatPng}`);
const light = require(`../images/light.${formatPng}`);
const activity = require(`../images/graf.${formatPng}`);
const ring = require(`../images/ring2.${formatPng}`);
const button = require(`../images/button.${formatPng}`);
const image = require(`../images/sync-api.${formatPng}`);

// event
const check = require(`../images/check.${formatPng}`);
const dnk = require(`../images/dnk.${formatPng}`);
const work = require(`../images/peoplePC.${formatPng}`);
const importEvant = require(`../images/box.${formatPng}`);
const gajets = require(`../images/gajets.${formatPng}`);
const control = require(`../images/ControlEventsWite.${formatPng}`);

// media
const imageMedia = require(`../images/employees/ap.${formatJpg}`);
const tild = require(`../images/media/tild3139-6535-4361-b363-326635353530__4_forbes.${formatJpg}`);
const apiMedia = require(`../images/media/900_450_crop.${formatJpg}`);
const privat24 = require(`../images/media/privat24.${formatPng}`);
const telecom = require(`../images/media/telecom.${formatJpg}`);
const pipeDrive = require(`../images/media/PipeDrive.${formatPng}`);
const tina = require(`../images/media/d1537fbd27.${formatJpg}`);
const pumbMedia = require(`../images/media/d295aca502535882a99f93645bda62bd_L.${formatJpg}`);
const robotMedia = require('../images/media/Depositphotos_165058806_m-2015.webp');
const corezoidOpenApi2016 = require(`../images/media/corezoid-oslo-open-api-2016.${formatPng}`);
const financialServicesSectors = require(`../images/media/financial-services-sectors.${formatJpg}`);
const findevrSf2015 = require(`../images/media/findevr-sf-2015.${formatPng}`);
const finovateSpring2015 = require(`../images/media/finovate-spring-2015.${formatPng}`);
const finovateSpring2016 = require(`../images/media/finovate-spring-2016.${formatPng}`);
const finovateTopleassAtm2014 = require(`../images/media/finovate-topleass-atm-2014.${formatPng}`);
const db2f04b9001c = require(`../images/media/8011365485db2f04b9001c.${formatJpg}`);
const newCoreAlternative = require(`../images/media/new-core-alternative.${formatPng}`);
const telecomVsMessengers = require(`../images/media/telecom-vs-messengers.${formatPng}`);
const visaBitcoinVityaz = require(`../images/media/visa-bitcoin-vityaz.${formatPng}`);
const vityazPrivatBunk = require(`../images/media/vityazPrivatbank.${formatPng}`);
const intelectBunk = require(`../images/media/intelectBunk.${formatPng}`);
const mumbu = require(`../images/media/Mambu_homepage_January2019.${formatJpg}`);
const metroMedia = require(`../images/media/metro-logo.${formatPng}`);
const medici = require(`../images/media/MEDICI_default.${formatJpg}`);
const deposit = require(`../images/media/Depositphotos_26671801_m-2015.${formatJpg}`);
const bitcoin = require(`../images/media/1480_Ly9jb2ludGVsZWdyYXBoLmNvbS9zdG9yYWdlL3VwbG9hZHMvdmlldy82ZjQyNWRhMTI3NWNjNWNjZTY1ZDYyNjI0YzgzYTA2NS5qcGc=.${formatJpg}`);
const chatbors = require(`../images/media/2015-08-20T223408Z_1_LYNXNPEB7J115_RTROPTP_4_MICROSOFT-LAUNCH-WINDOWS-10.${formatJpg}`);
const corezoidVisa = require(`../images/media/01_corezoid-visa-privatbank-teams.${formatJpg}`);
const gift = require(`../images/media/AR-170919740.${formatJpg}`);
const neobank = require(`../images/media/absolutecard.${formatPng}`);
const digital = require(`../images/media/digital.${formatJpg}`);
const monoTinkoff = require(`../images/media/monoTinkoff.${formatJpg}`);
const artjokerVCRU = require(`../images/media/ArtjokerVCRU.${formatPng}`);
const yelobankmedia = require(`../images/media/yelobankmedia.${formatJpg}`);


// webinars
const webinarsImage = require(`../images/tv.${formatPng}`);
const currency = require(`../images/currency-rates-via-email.${formatPng}`);

// footer
const imgAws = require(`../images/awsFooter.${formatPng}`);
const iconArrow = require('../images/arrow.svg');

// demo
const corezoidDemo = require(`../images/corezoid.${formatPng}`);

// Navigator
const softwareNavigator = require(`../images/software.${formatPng}`);
const apiNavigator = require(`../images/api.${formatPng}`);
const codeNavigator = require(`../images/code.${formatPng}`);
const processesNavigator = require(`../images/process.${formatPng}`);
const iotNavigator = require(`../images/iot.${formatPng}`);
const communicationNavigator = require(`../images/communication.${formatPng}`);
const eventsNavigator = require(`../images/ControlEvents.${formatPng}`);
const knowledgeNavigator = require(`../images/deepmemo-logo.${formatPng}`);
const peopleNavigator = require(`../images/people.${formatPng}`);
const cloudNavigator = require(`../images/cloud.${formatPng}`);


export {
  cloudCaab,
  apiCaab,
  light,
  activity,
  ring,
  button,
  image,
  check,
  dnk,
  work,
  importEvant,
  gajets,
  control,
  imageMedia,
  tild,
  apiMedia,
  privat24,
  telecom,
  pipeDrive,
  tina,
  pumbMedia,
  robotMedia,
  corezoidOpenApi2016,
  financialServicesSectors,
  findevrSf2015,
  finovateSpring2015,
  finovateSpring2016,
  finovateTopleassAtm2014,
  db2f04b9001c,
  newCoreAlternative,
  telecomVsMessengers,
  visaBitcoinVityaz,
  vityazPrivatBunk,
  intelectBunk,
  mumbu,
  metroMedia,
  medici,
  deposit,
  bitcoin,
  chatbors,
  corezoidVisa,
  gift,
  neobank,
  digital,
  monoTinkoff,
  webinarsImage,
  currency,
  imgAws,
  iconArrow,
  corezoidDemo,
  softwareNavigator,
  apiNavigator,
  codeNavigator,
  processesNavigator,
  iotNavigator,
  communicationNavigator,
  eventsNavigator,
  knowledgeNavigator,
  peopleNavigator,
  cloudNavigator,
  artjokerVCRU,
  yelobankmedia,
};
